import logo from './ham.png';
import './App.css';
import DynamicQuote from './DynamicQuote';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Spinning Logo" />
        <p>
          Fridge Boi.
        </p>
        <a
          className="App-link"
          href="https://knowyourmeme.com/memes/6o163-s-dog"
          target="_blank"
          rel="noopener noreferrer"
        >
          The One True Chonk
        </a>
        <DynamicQuote />
      </header>
    </div>
  );
}

export default App;
