import { useState } from "react";

const DynamicQuote = () => {
    const defaultQuote = 'A Storm of Swords';
    const [quote, setQuote] = useState('A Song of Ice and Fire');

    // on click event, inner text for dynamic quote updates to newText
    const updateQuote = (newQuote='') => {
        newQuote = (newQuote === 'random') ? getRandomQuote() : setQuote(defaultQuote);
        // setQuote(newQuote);
    }
    
    const getRandomQuote = () => {
        (async () => {
            const response = await fetch('https://api.quotable.io/random');
            const data = await response.json();
            // console.log(data)
            setQuote(data.content);
        })();
    }

    //TODO: generalize Quoute Refersh, get random quote (Done), reset standard quote(Done)
    // to set default when no arguments are passed

    return (
        <div className="Quote">
            <p title="dynamic quote">{quote}</p>
            <button className="QuoteReset" onClick={updateQuote}>Reset Quote</button>
            <button className="QuoteRandom" onClick={() => updateQuote('random')}>Get Random Quote</button>
        </div>
    );
}

export default DynamicQuote;